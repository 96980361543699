import React from 'react';
import './App.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Switch, Redirect, Route } from 'react-router-dom';
import Main from '../Main/Main'


function App() {
  return (
    <div className="App container" >
      <Header className='row' />
      <Switch className='row'>
        <Route path='/' exact component={Main} />
        <Redirect to='/' />
      </Switch>
      <Footer className='row' />
    </div>
  );
}

export default App;
