import React from 'react';
import './Main.css';

class Main extends React.Component {
  render() {
    return (
      <main className='container'>
        <p className='coming-soon'>
          Coming soon!
        </p>
      </main>
    );
  }
}

export default Main;