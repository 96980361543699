import React from 'react';
import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className='copyright'>
          &copy; 2020 TypingFly. All Rights reserved.
        </div>
      </footer>
    );
  }
}

export default Footer;