import React from 'react';
import { Link } from 'react-router-dom'
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFeather
} from '@fortawesome/free-solid-svg-icons';

class Header extends React.Component {
  render() {
    return (
      <header>
        <nav className='navbar'>
          <Link className='nav-item logo' to='/' >
            Typing<FontAwesomeIcon className='feather' icon={faFeather} />Fly
          </Link>
        </nav>
      </header >
    );
  }
};

export default Header;